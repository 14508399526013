html, body {
    background-color: white;
    height: 100%;
    width: 100%;
}

#root {
    height: 100%;
    width: 100%;
}

.container-fluid {
    height: 100%;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    overflow-x: hidden;
    background-color: white;
}

.home-banner {
    min-height: 700px;
}

.home-image-banner {
    position: relative;
    min-height: 700px;
    min-width: 1300px;
    overflow: hidden;
    left: -230px;
    background-size:cover;
    background-repeat: no-repeat;
    background-image: url('../design-resources/restaurant-background-2.jpg');
    z-index: 1;
}

.home-image-description {
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 350px;
    background-image: url('../design-resources/stock-restaurant.jpg');
}

.home-main-block {
    background-color: black;
}

.home-description {
    background: white;
}

.input-box {
    background-color: #ececec;
    border-color: #ececec;
    padding-top: 16px;
    padding-bottom: 8px;
    font-weight: 500;
}

.login-box {
    background-color: #ececec;
    border-color: #ececec;
    padding: 3%;
    border-radius: 4px;
    font-weight: 500;
}

.home-main-block {
    padding-top: 13%;
}

.card-banner {
    position: relative;
    width: 500px;
    margin-top: 140px;
    z-index: 9999;
}

.home-quote {
    background-color: #0c5a8b;
    height: 220px;
}


