.profile-component {
    position: relative;
    min-height: 800px;
    min-width: 1300px;
    overflow:visible;
    background-size:cover;
    background-repeat: no-repeat;
    background-image: url('../design-resources/restaurant-dashboard.png');
    z-index: 1;
}

.profile-main-block {
    background-color: white;
    padding: 80px;
    border-radius: 10px;
}

.hidden-element {
    visibility: hidden;
}