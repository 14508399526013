.dashboard-component {
    position: relative;
    min-height: 700px;
    min-width: 1300px;
    overflow: hidden;
    background-size:cover;
    background-repeat: no-repeat;
    background-image: url('../design-resources/restaurant-dashboard.png');
    z-index: 1;
}

.dashboard-main-block {
    background-color: white;
    padding: 95px;
    border-radius: 10px;
}

.disabled {
    pointer-events: none;
}